import classNames from 'classnames';

const Alert = ({ message, variant = 'success' }) => {
    const cls = classNames(
        'mb-8 mt-2 rounded-xl border p-4',
        {
            'border-green-950 text-green-950 bg-green-50':
                variant === 'success',
        },
        {
            'border-red-950 text-red-950 bg-red-50': variant === 'error',
        }
    );

    return <div className={cls}>{message}</div>;
};

export default Alert;
